import { Albert_Sans } from "next/font/google";

export const alber = Albert_Sans({
  display: "swap",
  style: ["normal"],
  subsets: ["latin"],
  weight: ["400", "600", "700"],
  variable: "--font-albert",
  adjustFontFallback: false,
});

export const fonts = {
  alber,
};
